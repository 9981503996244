// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.0-rc-9839(ce44cdc3c1)-C05/12/2023-18:02:54-B05/12/2023-18:22:15' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.0-rc-9839(ce44cdc3c1)-C05/12/2023-18:02:54-B05/12/2023-18:22:15",
  branch: "master",
  latestTag: "6.0-rc",
  revCount: "9839",
  shortHash: "ce44cdc3c1",
  longHash: "ce44cdc3c14488fb4c92db45ab649bcfde1b000d",
  dateCommit: "05/12/2023-18:02:54",
  dateBuild: "05/12/2023-18:22:15",
  buildType: "Ansible",
  } ;
