<div class="custom-toolbar">
  <div id="before-toolbar" class="custom-toolbar-element">
    <yo-help lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <p-calendar
      firstDayOfWeek="1"
      [locale]="fr"
      (onSelect)="onChangeFilterDateLivraison()"
      (onTodayClick)="onChangeFilterDateLivraison()"
      (onClearClick)="onChangeFilterDateLivraison()"
      placeholder="Filtrer par dates de livraison..."
      title="Filtrer par dates de livraison"
      appendTo="body"
      showButtonBar="true"
      selectionMode="range"
      [(ngModel)]="filterDatesLivraisons"
      dateFormat="dd/mm/yy"
      readonlyInput="true"
      [showWeek]="true">
    </p-calendar>
    <dx-tag-box
      class="box-size-1"
      [multiline]="false"
      [dataSource]="filterItemsFournisseur"
      placeholder="Filtrer par fournisseur..."
      displayExpr="value"
      valueExpr="id"
      [searchEnabled]="true"
      [showSelectionControls]="true"
      (onValueChanged)="onChangeFilterFournisseurs($event)"
    >
    </dx-tag-box>
    <dx-tag-box
      class="box-size-1"
      [multiline]="false"
      [dataSource]="filterItemsUdp"
      placeholder="Filtrer par unité de production..."
      displayExpr="value"
      valueExpr="id"
      [searchEnabled]="true"
      [showSelectionControls]="true"
      (onValueChanged)="onChangeFilterUdps($event)"
    >
    </dx-tag-box>
    <dx-tag-box
      class="box-size-2"
      [dataSource]="filterItemsListeBesoin"
      placeholder="Filtrer par liste de besoins..."
      displayExpr="value"
      valueExpr="id"
      [searchEnabled]="true"
      [showSelectionControls]="true"
      [multiline]="false"
      (onValueChanged)="onChangeFilterListesBesoins($event)"
    >
    </dx-tag-box>
  </div>
  <div id="after-toolbar" class="custom-toolbar-element">
    <yo-button
      iconClass="fas fa-print"
      (onClick)="printPDFCommandes()"
      [disabled]="isActionDisabled"
      tooltip="Imprimer les propositions sélectionnées au format pdf">
    </yo-button>
    <yo-button
      iconClass="fa fa-trash"
      buttonClass="cta-inner-delete"
      (onClick)="deleteBonCfs()"
      [disabled]="isActionDisabled"
      tooltip="Supprimer les propositions sélectionnées">
    </yo-button>
  </div>
</div>

<dx-data-grid
  [dataSource]="dataSource"
  [remoteOperations]="true"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  (onSelectionChanged)="checkDisabledButtons()"
  [allowColumnResizing]="true"
  #grid>
  <dxo-selection [deferred]="true" [allowSelectAll]="true" [showCheckBoxesMode]="checkBoxesMode"
                 mode="multiple"></dxo-selection>

  <dxi-column dataField="numeroBcf" caption="Bon de commande" alignement="left"></dxi-column>
  <dxi-column dataField="dateCommande" caption="Date commande" cellTemplate="templateDateCommande"
              alignement="left"></dxi-column>
  <dxi-column dataField="dateLivraisonAjustee" alignement="left" caption="Date livraison"
              cellTemplate="templateDateLivraison"></dxi-column>
  <dxi-column dataField="fournisseurLibelle" caption="Fournisseur"></dxi-column>
  <dxi-column dataField="uniteDeProductionLibelle" caption="Unité de production"
              cellTemplate="templateUdp"></dxi-column>
  <dxi-column dataField="montantHT" caption="Montant HT" alignment="left" cellTemplate="templateMontantHT"></dxi-column>
  <dxi-column dataField="commentaire" caption="Remarque(s)" alignment="left"
              cellTemplate="templateRemarques"></dxi-column>
  <dxi-column dataField="listeBesoinLibelle" caption="Liste de besoins" alignment="left"
              cellTemplate="templateLbde"></dxi-column>

  <div *dxTemplate="let cell of 'templateUdp'">
    <td>
      <yo-site [siteId]="cell.row.data.site.id" [siteLibelleTooltip]="cell.row.data.site.libelle"
               [siteLibelle]="cell.row.data.uniteDeProductionLibelle"></yo-site>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateDateCommande'">
    <td style="text-align: right;">
      {{ cell.row.data.dateCommande | date:'dd/MM/yyyy' | titlecase }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateDateLivraison'">
    <td style="text-align: right;">
      {{ cell.row.data.dateLivraisonAjustee | date:'dd/MM/yyyy' | titlecase }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateMontantHT'">
    <td style="text-align: right;">
      {{ cell.row.data.montantHT | currency:'EUR':'symbol':'1.4-4' }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateRemarques'">
    <td style="text-align: left;"
    >
      <p [innerHTML]="domSanitizer.bypassSecurityTrustHtml(utils.stripeHtml(cell.row.data.commentaire))">
      </p>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateLbde'">
    <td style="text-align: left;">
      <a title="Aller sur la liste de besoins"
         (click)="wkfSvc.goToListeBesoinsDetails(cell.row.data.workflowInstance)"><span
        class="cursor save-color">{{cell.row.data.listeBesoinLibelle}}</span></a>
    </td>
  </div>

  <dxo-sorting mode="single"></dxo-sorting>
  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} commande(s) refusée(s)">
  </dxo-pager>

</dx-data-grid>
